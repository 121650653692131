@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
:root {
  --pf23-color:#F5895B;
  --pf23-color-hover:#e06f3e;
  --pf23-svg-color: #EA4E4E;
  --bg-light-pf23: #F2F2F2;
  --bg-dark-pf23: #333333;
  --bs-border-radius: 2.5rem;
  --border-color-dark: #a0a0a0;
  --border-color-light: #BFBFBF;
  --bs-nav-link-padding-x: 0rem;
}

.pagination {
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: black;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: var(--border-color-light);
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--pf23-color);
  --bs-pagination-hover-bg: var(--bg-light-pf23);
  --bs-pagination-hover-border-color: var(--border-color-light);
  --bs-pagination-focus-color: var(--pf23-color);
  --bs-pagination-focus-bg: var(--bg-light-pf23);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem var(--pf23-color) (13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: var(--pf23-color);
  --bs-pagination-active-border-color: var(--pf23-color);
  --bs-pagination-disabled-color: var(--bg-dark-pf23);
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: var(--border-color-light);
  display: flex;
  padding-left: 0;
  list-style: none;
}

::-moz-selection {
  color:#fff;
  background-color: var(--pf23-color);
}
  
::selection {
color:#fff;
background-color:var(--pf23-color);
}


html, body {
  font-family: 'Work Sans', sans-serif;
  background-color: var(--bg-dark-pf23);
  min-height: 90vh;
}

.App {
  position: relative;
  text-align: center;
  width: auto;
  height: 100%;
  border-radius: 3rem;
}

.with-sidebar {
  margin-left: 270px;
}

.navbar {
  font-size: 1.25rem;
}

.overflow-sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

.dropdown-toggle.nav-link::after {
  content: none;
}

.sidebar-footer {
  color: white;
}

.pf23-logo {
  width: 40px;
  vertical-align: baseline;
  margin-left: 7px
}

.pf23-color {
  color: var(--pf23-color);
}

.pf23-color:hover, .pf23-color:focus, .pf23-color:hover:active  {
  color: var(--pf23-color-hover);
}

.nav-link {
  color: white;
  display: flex;
  align-items: center;
}

a.nav-link:hover, a.nav-link:focus {
  color: var(--pf23-color);
}

.nav-link:not(a.task):hover .st0, .nav-link.task:hover .st1, .nav-link:not(a.task):focus .st0, .nav-link.task:focus .st1 {
  stroke: var(--pf23-color);
}

.offcanvas {
  background-color: var(--bg-dark-pf23);
  color: white;
}

.offcanvas .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.cursor-disabled {
  pointer-events: none !important;
  opacity: 0.3;
}

.margin-sidebar {
  margin-left: 250px;
}

.App-header h1 {
  margin-top: 2rem;
}

.bg-pf23 {
  background-color: var(--bg-pf23);
}

.bg-light-pf23 {
  background-color: var(--bg-light-pf23);
}

.list-group-item.active {
  background-color: var(--pf23-color);
  border-color: var(--pf23-color);
}

.scrapfinder-logo{
  height: 35px;
  width: auto;
  margin-right: 5px;
}

.user-icon {
  height: 45px;
  margin-right: 5px;
}

.user-icon span {
  color: #A0A0A0;
}

.user-icon span:hover {
  color: var(--pf23-color) !important;
}

.sidebar-icon {
  width: 35px;
  margin-right: 5px;
}

.custom-table {
  min-height: 300px;
}

.table>:not(thead)>*>* {
  padding: 0.1rem;
}

.eretailer-table>:not(thead)>*>* {
  padding: .5rem 0rem;
}

.table-icon {
  width: 35px;
}

.dropdown-menu.show {
  left: auto;
  right: 0
}
.dropdown-item:hover, .dropdown-item:focus-visible {
  background-color: var(--pf23-color-hover);
  color: white;
  outline: none;
}

.highlighted {
  background-color: var(--pf23-color-hover);
  color: #fff;
}

button {
  border-radius: .5rem !important;
  color: #FFFFFF !important;
  text-decoration: none !important;
  border: 0 !important;
}

button.btn-pf23 {
  background-color: var(--pf23-color);
  padding: .75rem;
}

button.btn-dark {
  background-color: var(--bg-dark-pf23) !important;
}

button.btn-modal {
  background-color: var(--pf23-color);
}

.btn:hover:not(.btn-none, .btn-filter) {
  background-color: var(--pf23-color-hover) !important;
}

.hyperlink {
  color: inherit;
  text-decoration: none;
  text-decoration: underline;
}

.hyperlink:hover {
  color: var(--pf23-color-hover);
}

button.btn-pf23:hover, button.btn-modal:hover, button.btn-modal:focus, button.btn-modal:visited, button.btn-modal:active, button.btn-modal:focus-visible 
button.btn-dark:hover, button.btn-dark:focus, button.btn-dark:visited, button.btn-dark:active, button.btn-dark:focus-visible {
  background-color: var(--pf23-color-hover) !important;
}

button.btn-dark.active {
  background-color: var(--pf23-color) !important;
}

button.btn-filter {
  color: #6c757d !important;
  background-color: #fff;
  border: 1px solid #ced4da !important;
}

button.btn-filter:active, button.btn-filter:focus, button.btn-filter:hover {
  color: #000 !important;
  background-color: #fff;
  border: 1px solid var(--pf23-color) !important;
}


circle.st0 {
  stroke: var(--bg-dark-pf23);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s !important;
}


.form-control:focus {
  border-color: var(--pf23-color);
}

.form-control:focus {
    box-shadow: none;
}

.form-check-input:checked {
  background-color: var(--pf23-color);
  border-color: var(--pf23-color);
}

.form-check-input:focus {
  border-color: var(--pf23-color);
  box-shadow: 0 0 0 0.25rem rgba(245,137,91,.25);
}

.user-icon.main:hover {
  stroke: #e06f3e !important;
}

#project-dropdown .dropdown-item {
  text-wrap: wrap;
}

#project-dropdown .dropdown-toggle {
  width: 100%;
  white-space: nowrap;
}

.modal #project-dropdown button {
  width: 100%;
}

.head-div {
  padding: 13px 30px !important;
}

.bg-image {
  background-image: url('../src/assets/background.png');
  background-position: center;
  background-size: cover;
  border-radius: 3rem;
}

.logo{
  width: 275px;
}

.home-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-buttons button {
  width: 8rem;
  margin-left: 5px;
  margin-right: 5px;

}

.logo-loading{
  width: 200px;
}

.btn-close:focus {
  box-shadow: none;
}

.form-text {
  font-size: .8rem;
}

/* ALERTS */

.alert {
  left: 0;
  margin: auto;
  position: absolute; 
  right: 0;
  text-align: center;
  top: 1em;
  width: 80%;
  z-index: 1;
}


.alert-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.alert-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
  transform: translateY(0);
}

.alert-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

.mobile-menu {
  color: #A0A0A0 !important;
  position: relative;
}
.mobile-menu svg {
  vertical-align: baseline;
}

.table-icon.cancel-task {
  fill: #A0A0A0;
  height: 35px;
}

.table-icon.cancel-task-active {
  fill: var(--pf23-svg-color);
  height: 35px;
}

.mobile-header-border {
  border-bottom: 1px solid rgba(255, 255, 255, .5)
}

.brand-logo::after {
  content: '  Scrapfinderlab'
}
.brand-logo {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.brand-logo:hover {
  color: inherit;
}

.text-dark:hover {
  color: var(--pf23-color-hover) !important;
}

pre {
  background-color: #000;
  font-family: 'monospace';
  color: white;
  font-size: 12px;
  padding: 10px 5px;
  border-radius: 10px;
}

.nav-tabs {
  background-color: transparent !important;
}

.nav-tabs .nav-link {
  color: #000 !important;
}

.nav-tabs .nav-link.active {
  background-color: var(--pf23-color);
  color: #fff !important;
}

.nav-tabs .nav-link {
  justify-content: space-around !important;
}

.user-files-tabs {
  margin: 0px 10px;
  flex-shrink: 1 !important;
  width: 100%;
}
.user-files-tabs li button {
  border-radius: 0px !important;
}

.user-files table th {
  width: 33% !important;
}

.user-files table td {
  height: 50px;
}

.sample-output table td {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.nav-item:first-of-type:not(.user-icon), .nav-item:nth-of-type(2) {
  border-right: 1px solid #dee2e6;
}

.filter-files-checkboxes label input {
  margin-right: 3px;
}

.download-icon svg {
  height: 25px;
  width: 25px;
}

input[type="checkbox"] {
  accent-color: #ff5000;
}

input:read-only {
  background-color: #e9ecef;
  cursor: auto;
}

.sort-arrow {
  cursor: pointer;
}

.count {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  padding: 5px;
  margin: 0 5px;
}

.status-dropdown {
  color: #000 !important;
}

.quick-start img, .documentation img, .faq img {
  max-width: 100% !important;
}

.download-icon.detailview svg {
  padding: 3px;
}

.files-table th {
  min-width: 200px;
}

.canvasjs-chart-credit {
  /* color: #fff !important; */
  display: none;
}

.region-dropdown {
  width: 56%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.region-dropdown-menu {
  width: 56%;
  background-color: var(--bg-dark-pf23);
}

.region-dropdown-menu .dropdown-item {
  color: white !important;
}

.select-arrow {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDE1bC02LTYgMS0xIDUgNSA1LTUgMSAxLTYgNiIgZmlsbD0iIzAwMCIvPgo8L3N2Zz4=') no-repeat right center;
}

.expanded-row-content {
  font-size: inherit;
  color: inherit;
  background-color: inherit;
  font-family: inherit;
}

.table-cell {
  max-width: 25vw; /* 10% of the viewport width */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-cell input {
  max-width: 25vw;
}

.table-cell a {
  display: block;
  max-width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hyperlink-icon {
  display: flex;
  align-items: center;
  color: var(--pf23-color);
  width: 30px;
}

.create-chained-spider-button:disabled {
  background-color: lightgray
}

@media only screen and (max-width: 768px) {
  .App {
    margin-left: 0px;
  }
  .mobile-menu {
    z-index: 150;
    display: block;
  }
  .icon-suffix {
    display: block;
  }
  .copywright {
    display: none;
  }
  .hide-on-mobile {
    display: none !important;
  }
  .documentation {
    margin-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .fs-5 {
    font-size: 1rem !important;
  }
  .form-control {
    font-size: .9rem !important;
  }
  .fixed-left {
    position: absolute !important;
  }
  .refresh {
    text-align: left !important;
    padding-top: 5px
  }
  .pagination {
    --bs-pagination-padding-x: 0.4rem;
    --bs-pagination-padding-y: 0.275rem;
  }
  .go-back {
    margin: 0 0 0 38px !important;
    padding-left: 3px;
  }
  .spider-name-md-5 {
    flex: 0 0 auto;
    width: 98% !important;
  }
  .logo{
    width: 175px;
  }
  h3.fw-semibold {
    font-size: 1.25rem !important;
  }
  div.fw-semibold:not(.fs-5) {
    font-size: .8rem !important;
  } 
  .home-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .home-buttons button {
    width: 100%;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}

@media only screen and (min-width: 769px) {
  .icon-suffix {
    display: none;
  }
  /* .documentation {
    margin-left: -85px;
  } */
  .mobile-menu {
    z-index: inherit;
    display: none;
  }
  
  .refresh {
    text-align: right !important;
  }
  .pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
  }
  .go-back {
    margin: 0 !important;
  }
  .spider-name-md-5 {
    flex: 0 0 auto;
    width: 39% !important;
  }
}

.Embeded-container {
  height: 90vh;
  width: 100%;
}

.scrollableForm {
  max-height: 60vh; /* or a specific px value that suits your design */
  overflow-y: auto;
  overflow-x: hidden;
}